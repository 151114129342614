<template>
  <div class="col-md-12">
    <div
      aria-hidden="true"
      class="modal fade modal_cust show"
      role="dialog"
      tabindex="-1"
      id="salesItemList"
      ref="salesItemModal"
      v-if="modalId == 'salesItemList'"
    >
      <div
        class="
          modal-dialog
          listing-popup-place
          modal_ac_head
          item-container-place
        "
        role="document"
      >
        <div class="modal-content search-table-container">
          <div class="form-group has-search mb-0">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              class="form-control border-0"
              placeholder="Search"
              v-model="itemSearched"
              @input="searchItem"
              ref="itemSearch"
            />
          </div>
          <div
            class="mx-2 px-2 mt-2"
            v-if="dataLists6.sales_setting.item_selection_design == 1"
          >
            <div class="row catagory-section">
              <div class="px-1 mb-2">
                <button
                  class="btn btn-xs btn-primary p-1 font-s"
                  @click="selectItemGroup((itemGroup = null), 'all')"
                  :class="{ active: isActive == 'all' }"
                >
                  All
                </button>
              </div>
              <div
                class="px-1 mb-2"
                v-for="(itemGroup, index) in itemGroups"
                :key="index"
              >
                <button
                  :ref="'btn' + index"
                  class="btn btn-xs btn-primary p-1 font-s"
                  :class="{ active: isActive == index }"
                  @click="selectItemGroup(itemGroup, index)"
                >
                  {{ itemGroup.title }}
                </button>
              </div>
            </div>
          </div>
          <hr
            class="my-0"
            v-if="dataLists6.sales_setting.item_selection_design == 1"
          />
          <table
            class="table table1"
            v-if="dataLists6.sales_setting.item_selection_design == 0"
          >
            <thead>
              <tr>
                <th scope class="wd-5p table-start-item">S.N.</th>
                <th scope class="wd-15p">Purchase Id</th>
                <th scope class="wd-15p">Item Id</th>
                <th scope class="wd-25p">Item Name</th>
                <th scope class="wd-15p">Category</th>
                <th scope class="wd-15p">Brand</th>
                <th scope class="wd-15p text-center">Available</th>
                <th scope class="wd-10p text-center">Discount</th>
                <th scope class="wd-15p text-right table-end-item">Rate</th>
              </tr>
            </thead>
            <tbody v-if="!itemLoading && itemsByBranch.items.length > 0">
              <tr
                v-for="(item, index) in itemsByBranch.items"
                :key="`${index}${item.name}`"
                @click="selectItem(item.id)"
                data-dismiss="modal"
              >
                <th scope="row" class="table-start-item">{{ ++index }}</th>
                <td>
                  <span v-if="item.purchase_id"
                    >{{ item.purchase_detail.prefix
                    }}{{ item.purchase_detail.purchase_no }}</span
                  >
                  <span v-else>NonStock</span>
                </td>
                <td>{{ item.item.item_id }}</td>
                <td>{{ item.item.name }}</td>
                <td>{{ item.item.item_group.title }}</td>
                <td v-if="item.item.brand_id">{{item.item.brand.name}}</td>
                <td v-else>-</td>
                <td class="text-center">
                  <span v-if="item.item.stock_required == 0">-</span>
                  <span v-else
                    >{{ item.available }} {{ item.item.unit_type.title }}</span
                  >
                </td>
                <td class="text-center">
                  {{ parseDigit(item.sales_discount_amount) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigit(item.sales_rate) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="itemLoading || itemSearchLoading">
              <tr>
                <td colspan="11" style="text-align: center">Loading.....</td>
              </tr>
            </tbody>
            <tbody v-else-if="!itemLoading && itemsByBranch.items.length == 0">
              <tr>
                <td colspan="11" style="text-align: center">
                  No records found.
                </td>
              </tr>
            </tbody>

            <tbody v-else-if="error">
              Error Fetching data from the server.
            </tbody>
          </table>
          <div
            class="m-2 px-2"
            v-if="dataLists6.sales_setting.item_selection_design == 1"
          >
            <div
              class="row"
              v-if="!itemLoading && itemsByBranch.items.length > 0"
            >
              <div
                class="col-2 px-1 mb-2"
                v-for="(item, index) in itemsByBranch.items"
                :key="index"
                @click="selectItem(item.id)"
                data-dismiss="modal"
              >
                <div class="card item-container">
                  <div class="card-item-image">
                    <img
                      class="popup_img"
                      :src="
                        item.item.image
                          ? item.item.image
                          : '/assets/img/logosmall.png'
                      "
                    />
                  </div>
                  <div class="card-item-price">
                    <div class="font-s">
                      NPR
                      {{
                        parseFloat(
                          item.sales_rate -
                            (item.sales_rate * item.sales_discount_percentage) /
                              100
                        ).toFixed(2)
                      }}
                    </div>
                    <div
                      class="font-xs text-bold"
                      v-if="item.sales_discount_percentage > 0"
                    >
                      <span class="text-danger text-line-throurh"
                        >NPR {{ item.sales_rate }}</span
                      >
                      -
                      <span class="text-success"
                        >{{ item.sales_discount_percentage }} %</span
                      >
                    </div>
                  </div>
                  <div class="card-item-detail p-1">
                    <div class="text-white text-capitalize">
                      {{ item.item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row message-center"
              v-else-if="
                !itemLoading &&
                itemsByBranch.items.length == 0 &&
                !itemSearchLoading
              "
            >
              <p>No records found.</p>
            </div>
            <div
              class="row message-center"
              v-else-if="itemLoading || itemSearchLoading"
            >
              <p>Loading....</p>
            </div>
            <div class="row message-center" v-else-if="errorItemSearch">
              Error Fetching data from the server.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Service";
import _ from "lodash";

export default {
  data() {
    return {
      isActive: "all",
      itemSearched: "",
      itemStockable: false,
      selectedItemGroup: "",
      itemLoading: false,
      errorItemSearch: false,
      items: {
        unit_type: null,
        vat_applicable: null,
        item_id: null,
        name: null,
        available: null,
        quantity: null,
        sales_rate: null,
        sales_discount_percentage: null,
        sales_discount_amount: null,
        total: null,
        barcode: null,
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters(["modalWithMode", "modalId", "dataLists6"]),
    ...mapGetters("sales", [
      "salesItem",
      "itemsByBranch",
      "branchId",
      "itemGroups",
      "itemSearchLoading",
    ]),
  },
  methods: {
    // focuses the input field when the item/customer modal is opened
    focusSearch(searchFieldId) {
      setTimeout(() => {
        this.$refs[searchFieldId].focus();
      }, 500);
    },

    selectItem(id) {
      this.itemStockable = true;
      let details = this.itemsByBranch.items.find(function (data) {
        return data.id == id;
      });
      this.$store.commit("sales/setSalesItem", details);
      this.$emit("clicked", details);
    },

    selectItemGroup(itmGroup, index) {
      if (itmGroup) {
        this.selectedItemGroup = itmGroup.slug;
      } else {
        this.selectedItemGroup = "";
      }
      this.isActive = index;
      const params = {
        branch: this.branchId,
        keyword: this.itemSearched,
        "item-group": this.selectedItemGroup,
      };
      this.ajaxSearchFilter(params);
    },

    searchItem: _.debounce(function () {
      this.itemLoading = true;
      const params = {
        branch: this.branchId,
        keyword: this.itemSearched,
        "item-group": this.selectedItemGroup,
      };
      this.ajaxSearchFilter(params);
    }, 600),

    ajaxSearchFilter(params) {
      this.itemLoading = true;
      Services.Sales.getItemsBySearchKeyword(params)
        .then((response) => {
          let value = response.data.data;
          let itemsByBranch = this.itemsByBranch;
          itemsByBranch["items"] = value.items;
          this.$store.commit("sales/setItemsByBranch", itemsByBranch);
        })
        .catch((error) => {
          this.errorItemSearch = true;
          console.log(error);
        })
        .finally(() => {
          this.itemLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.active {
  color: #1b52bf !important;
  background: white !important;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  top: 4px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.message-center p {
  text-align: center;
  color: var(--blue);
  font-size: large;
  margin-left: 10px;
}
</style>